import { orderStyleFormatter } from '../../app/modules/shared/util/orderStyleFormatter';

const SET_CUTTING_ORDERS = 'SET_CUTTING_ORDERS';
const SET_CUTTING_ORDER = 'SET_CUTTING_ORDER';
export const GET_CUTTING_ORDER = 'GET_CUTTING_ORDER';
const SET_IS_LOADING = 'SET_IS_LOADING';

const initialState = {
    cuttingOrders: [],
    cuttingOrder: null,
    isLoading: true,
};

export const cuttingOrdersSelector = (state) =>
    state.cuttingOrder.cuttingOrders;
export const cuttingOrderSelector = (state) => state.cuttingOrder.cuttingOrder;
export const isLoadingSelector = (state) => state.cuttingOrder.isLoading;

export const setCuttingOrders = (data) => ({
    type: SET_CUTTING_ORDERS,
    payload: data,
});

export const setCuttingOrder = (cuttingOrder, processCO) => ({
    type: SET_CUTTING_ORDER,
    payload: {
        cuttingOrder,
        processCO,
    },
});

export const getCuttingOrder = (payload) => ({
    type: GET_CUTTING_ORDER,
    payload,
});

export const setIsLoading = (payload) => ({
    type: SET_IS_LOADING,
    payload,
});

export const CuttingOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CUTTING_ORDERS: {
            return { ...state, cuttingOrders: action.payload };
        }
        case GET_CUTTING_ORDER: {
            return { ...state, isLoading: true };
        }
        case SET_CUTTING_ORDER: {
            const { cuttingOrder, processCO = true } = action.payload;

            if (cuttingOrder && processCO) {
                const { formattedData, sizeQuantities, poSizes, poColors } =
                    orderStyleFormatter(
                        cuttingOrder.productionOrder.orderStyles
                    );
                const sortedSizes = Object.values(poSizes).sort(
                    (a, b) => a.order - b.order
                );

                return {
                    ...state,
                    cuttingOrder: {
                        ...cuttingOrder,
                        poSizes,
                        poColors,
                        sortedSizes,
                        poFormattedStyles: { formattedData, sizeQuantities },
                        canAddBatches:
                            cuttingOrder.marker.isMainFabric &&
                            !cuttingOrder.bundledQuantity,
                        canEdit: !cuttingOrder.bundledQuantity,
                    },
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    cuttingOrder: cuttingOrder
                        ? {
                              ...state.cuttingOrder,
                              ...cuttingOrder,
                          }
                        : null,
                };
            }
        }
        case SET_IS_LOADING: {
            return { ...state, isLoading: action.payload };
        }
        default:
            return state;
    }
};
